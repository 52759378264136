import { EligibilityFileFormatType } from '@/components/pages/Account/EligibilityFileFormat';

import { IntegrationType } from './store/reducers';

export interface EligibilityValidator {
	validation_name: string;
	validation_type: 'text' | 'date' | 'email' | 'phone' | 'numeric';
	validation_display_name: string;
	is_required_field_at_redemption: boolean;
}

export enum ErrorConfigsType {
	Eligibility = 'eligibility_error',
}

export enum ErrorButtonType {
	Email = 'email',
	Phone = 'phone',
	URL = 'url',
}

export interface ErrorConfigs {
	error_type: ErrorConfigsType;
	error_body: string | null;
	error_button_text: string | null;
	error_button_type: ErrorButtonType | null;
	error_button_data: string | null;
}

export interface ReportConfig {
	report_type:
		| 'registered_users'
		| 'logins'
		| 'checkins'
		| 'clinical_programs'
		| 'health_sessions'
		| 'referrals'
		| 'survey_responses'
		| 'conditions';
	frequency: string | null;
}

export interface FileProcessingResultOutboundConfig {
	type: 'secure_share';
}

export interface SurveyConfig {
	survey_id: string;
	survey_type: string;
	all_questions_required: boolean;
	show_screening_results: boolean;
}

export interface HealthConfig {
	partner_id: string;
	baa_data_retention: boolean;
	enable_data_feed_override: boolean;
	hide_all_referrals: boolean;
	landing_header: string;
	landing_body: string;
	client_support_email: string | null;
	client_support_phone: string | null;
	integration_type: IntegrationType;
	client_id: string | null;
	eligibility_validators: EligibilityValidator[];
	report_configs: ReportConfig[];
	file_processing_result_outbound_configs: FileProcessingResultOutboundConfig[];
	light_logo_url: string | null;
	dark_logo_url: string | null;
	square_logo_url: string | null;
	survey_configs: SurveyConfig[];
	data_feed_delivery_method: 'none' | 'sftp' | 'secure_portal';
	technical_contact: string | null;
	eligibility_file_format: EligibilityFileFormatType | null;
	error_configs: ErrorConfigs[];
}

export type LinkOutType = 'url' | 'phone' | 'sms' | 'text';

export interface HealthReferral {
	id: string;
	sponsorship_id: string;
	logo_url: string;
	uploaded_logo?: File;
	referral_defn_id: HealthReferralDefinitions;
	title?: string;
	description: string;
	banner_title: string;
	banner_subtitle: string;
	link_out_1_text: string;
	link_out_1_type: string;
	link_out_1_data: string;
	link_out_1_override: number;
	link_out_2_text: string;
	link_out_2_type: string | null;
	link_out_2_data: string;
	link_out_2_override: number;
	link_out_3_text: string;
	link_out_3_type: string | null;
	link_out_3_data: string;
	link_out_3_override: number;
}

export enum HealthReferralDefinitions {
	PHQ_0 = 'phq-and-gad-eq-0',
	PHQ_1_4 = 'max-phq-or-gad-1-4',
	PHQ_5_9 = 'max-phq-or-gad-5-9',
	PHQ_10_14 = 'max-phq-or-gad-10-14',
	PHQ_15 = 'max-phq-or-gad-gte-15',
	CRISIS = 'crisis-response',
}

export const REFERRAL_DEFN_ID_ARRAY: HealthReferralDefinitions[] = [
	HealthReferralDefinitions.PHQ_0,
	HealthReferralDefinitions.PHQ_1_4,
	HealthReferralDefinitions.PHQ_5_9,
	HealthReferralDefinitions.PHQ_10_14,
	HealthReferralDefinitions.PHQ_15,
	HealthReferralDefinitions.CRISIS,
];

export type AssignmentRuleAttributeType = 'segment' | 'country';
export type AssignmentRuleOperator = 'and' | 'or' | 'true';

export interface HealthAssignmentRuleAttribute {
	type: AssignmentRuleAttributeType;
	value: string;
}

export interface HealthAssignmentRule {
	sponsorship_id: string;
	id: string;
	operator: AssignmentRuleOperator;
	assignment_rule?:
		| {
				attributes?: HealthAssignmentRuleAttribute[] | null | undefined;
		  }
		| null
		| undefined;
}

type DatetimeIso8601String = string;
type DurationIso8601String = string;

export interface HealthSponsorship {
	id: string;
	partner_id: string;
	display_name: string;
	description: string | null;
	starts_at: DatetimeIso8601String;
	ends_at: DatetimeIso8601String;
	auto_expire_duration: DurationIso8601String | null;
	eligibility_grace_period_duration: DurationIso8601String;
	is_demo: boolean;
	is_dependent_group: boolean;
	assignment_rules?: HealthAssignmentRule[];
}
