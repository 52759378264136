import { DateTime } from 'luxon';
import { FormEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import {
	Button,
	Card,
	FontSizes,
	FontWeights,
	Loader,
	Text,
	TextElements,
	units,
} from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import useHealthConfig from '@/hooks/api/useHealthConfig';
import { useHealthReferrals, useSubmitHealthReferral } from '@/hooks/api/useHealthReferrals';
import {
	useDeleteHealthSponsorship,
	useHealthSponsorship,
	useSubmitHealthSponsorship,
} from '@/hooks/api/useHealthSponsorships';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePermissions } from '@/hooks/auth';
import { useHealthReferralForms, useHealthReferralSubmitData } from '@/hooks/forms/useHealthReferralForm';
import {
	useHealthSponsorshipForm,
	useHealthSponsorshipSubmitData,
} from '@/hooks/forms/useHealthSponsorshipForm';
import {
	HealthReferral,
	HealthReferralDefinitions,
	HealthSponsorship,
	REFERRAL_DEFN_ID_ARRAY,
} from '@/types/health';
import { IntegrationType } from '@/types/store/reducers';
import { calmLogger } from '@/utils/calmLogger';
import { createUrl } from '@/utils/url';

import StickySaveButton from '../StickySaveButton';
import HealthSponsorshipPreview from '../TabRouter/sections/HealthConfig/Previews/HealthSponsorshipPreview';
import ReferralLinkoutPreview from '../TabRouter/sections/HealthConfig/Previews/ReferralLinkoutPreview';
import ReferralOverviewPreview from '../TabRouter/sections/HealthConfig/Previews/ReferralOverviewPreview';
import { PreviewWrapper } from '../TabRouter/sections/HealthConfig/styles';
import AssignmentRuleForm from './AssignmentRuleForm';
import BaseSponsorshipForm from './BaseSponsorshipForm';
import DeleteHealthSponsorshipModal from './DeleteHealthSponsorModal';
import ReferralCard from './ReferralCard';
import { ConfigWrapper, Wrapper } from './styles';

function HealthSponsorshipForm({
	partnerId,
	sponsorship,
	referrals,
}: {
	partnerId: string;
	sponsorship?: HealthSponsorship;
	referrals?: HealthReferral[];
}): ReactElement {
	const history = useHistory();
	const isDefault = sponsorship?.assignment_rules?.[0].operator === 'true';
	const partner = useDefinedPartner();
	const partnerIsLive = DateTime.fromISO(partner.contract_starts_at) < DateTime.now();

	const [hasValidPermissions, actions] = usePermissions();
	const [submitLoading, setSubmitLoading] = useState(false);
	const { data: healthConfig } = useHealthConfig({ partnerId: partner.id });
	const { search } = useLocation();
	const isSponsorCodePartner = healthConfig?.integration_type === IntegrationType.GROUP_CODE;
	const isDependentGroup =
		sponsorship?.is_dependent_group || new URLSearchParams(search).get('isDependentGroup') === 'true';
	const {
		baseFormProps,
		assignmentRuleOperatorFormProps,
		assignmentRuleAttributeFormProps,
		hasChangedAny,
		hasTouchedAny,
		addAssignmentRuleAttribute,
		removeAssignmentRuleAttribute,
	} = useHealthSponsorshipForm(sponsorship, isDefault, isDependentGroup);
	const {
		healthReferralFormProps: healthReferralFormProps,
		hasChangedAny: referralHasChangedAny,
		hasTouchedAny: referralHasTouchedAny,
	} = useHealthReferralForms(referrals);
	const { getHealthSponsorshipSubmitData, showValidationErrors } = useHealthSponsorshipSubmitData(
		baseFormProps,
		assignmentRuleOperatorFormProps,
		assignmentRuleAttributeFormProps,
	);
	const { getHealthReferralSubmitData, showValidationErrors: showReferralValidationErrors } =
		useHealthReferralSubmitData(healthReferralFormProps);

	const [submitHealthSponsorship] = useSubmitHealthSponsorship();
	const [submitHealthReferrals] = useSubmitHealthReferral();
	const deleteHealthSponsorship = useDeleteHealthSponsorship();

	const [previewMode, setPreviewMode] = useState<'sponsorship' | 'referral-overview' | 'referral-linkout'>(
		'sponsorship',
	);
	const [modalIsOpen, openModal] = useState(false);
	const onFocusSponsorship = useCallback(() => setPreviewMode('sponsorship'), []);
	const onFocusReferralOverview = useCallback(() => setPreviewMode('referral-overview'), []);
	const onFocusReferralLinkout = useCallback(() => setPreviewMode('referral-linkout'), []);

	const [selectedReferralDefinition, _setSelectedReferralDefinition] = useState<HealthReferralDefinitions>(
		HealthReferralDefinitions.PHQ_0,
	);
	const setSelectedReferralDefinition: typeof _setSelectedReferralDefinition = useCallback(
		(...args) => {
			if (!['referral-overview', 'referral-linkout'].includes(previewMode)) {
				onFocusReferralOverview();
			}
			_setSelectedReferralDefinition(...args);
		},
		[_setSelectedReferralDefinition, previewMode, onFocusReferralOverview],
	);
	const selectedReferralFormProps = useMemo(() => {
		const index = REFERRAL_DEFN_ID_ARRAY.indexOf(selectedReferralDefinition);
		return healthReferralFormProps[index];
	}, [healthReferralFormProps, selectedReferralDefinition]);
	const selectedReferralLogoUrl = useMemo(() => {
		if (selectedReferralFormProps.model.uploaded_logo?.[0]) {
			return createUrl(selectedReferralFormProps.model.uploaded_logo?.[0] as File);
		}
		return stringFromModelValue(selectedReferralFormProps.model.logo_url);
	}, [selectedReferralFormProps]);

	const onSubmit = useCallback(
		async (e: FormEvent): Promise<void> => {
			e.preventDefault();
			if (showValidationErrors() || showReferralValidationErrors()) return;
			const healthSponsorshipSubmitData = getHealthSponsorshipSubmitData(isDependentGroup);
			const healthReferralSubmitData = getHealthReferralSubmitData();

			try {
				setSubmitLoading(true);
				const sponsorshipID = await submitHealthSponsorship(
					healthSponsorshipSubmitData,
					partnerId,
					sponsorship?.id,
				);
				if (sponsorshipID) {
					await submitHealthReferrals(healthReferralSubmitData, partnerId, sponsorshipID);
					if (!healthReferralSubmitData[0].id) {
						history.push(`/${partnerId}/account#healthConfig`);
					}
				}
				baseFormProps.resetAllDirtyStates();
				assignmentRuleOperatorFormProps.resetAllDirtyStates();
				assignmentRuleAttributeFormProps.forEach(form => form.resetAllDirtyStates());
				healthReferralFormProps.forEach(form => form.resetAllDirtyStates());
			} catch (error) {
				calmLogger.error('Error in HealthSponsorshipForm onSubmit', {}, error);
			} finally {
				setSubmitLoading(false);
			}
		},
		[
			getHealthSponsorshipSubmitData,
			getHealthReferralSubmitData,
			partnerId,
			showValidationErrors,
			showReferralValidationErrors,
			sponsorship?.id,
			submitHealthSponsorship,
			submitHealthReferrals,
			history,
			baseFormProps,
			assignmentRuleOperatorFormProps,
			assignmentRuleAttributeFormProps,
			healthReferralFormProps,
			isDependentGroup,
		],
	);

	const onDelete = useCallback(async () => {
		if (!sponsorship?.id) {
			openModal(false);
			return;
		}

		const didDelete = await deleteHealthSponsorship(partnerId, sponsorship.id);
		openModal(false);
		if (didDelete) {
			history.push(`/${partnerId}/account#healthConfig`);
		}
	}, [deleteHealthSponsorship, history, partnerId, sponsorship?.id]);

	return (
		<form onSubmit={onSubmit} style={{ maxWidth: units(128), marginBottom: '8rem' }}>
			<Text el={TextElements.H2} size={FontSizes.xl} weight={FontWeights.Regular}>
				{sponsorship?.display_name ??
					(isDependentGroup ? 'New Friends & Family Sponsor Group' : 'New Sponsor Group')}
			</Text>
			<Wrapper>
				<ConfigWrapper>
					<BaseSponsorshipForm formProps={baseFormProps} onFocus={onFocusSponsorship} />
					{!isDefault && (
						<AssignmentRuleForm
							isSponsorCodePartner={isSponsorCodePartner}
							isDependentGroup={isDependentGroup}
							assignmentRuleOperatorFormProps={assignmentRuleOperatorFormProps}
							assignmentRuleAttributeFormProps={assignmentRuleAttributeFormProps}
							addAssignmentRuleAttribute={addAssignmentRuleAttribute}
							removeAssignmentRuleAttribute={removeAssignmentRuleAttribute}
							onFocus={onFocusSponsorship}
						/>
					)}
					<ReferralCard
						allHealthReferralFormProps={healthReferralFormProps}
						selectedReferralFormProps={selectedReferralFormProps}
						selectedReferralDefinition={selectedReferralDefinition}
						setSelectedReferralDefinition={setSelectedReferralDefinition}
						onFocusOverview={onFocusReferralOverview}
						onFocusLinkout={onFocusReferralLinkout}
						isDependentGroup={isDependentGroup}
					/>
					{sponsorship?.id &&
						!partnerIsLive &&
						hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE]) && (
							<Card>
								<CellTitle>Delete Sponsor Group</CellTitle>
								<Button onPress={() => openModal(true)} backgroundColor="errorRed">
									Delete Sponsor Group
								</Button>
							</Card>
						)}
				</ConfigWrapper>
				<PreviewWrapper>
					{previewMode === 'sponsorship' ? (
						<HealthSponsorshipPreview
							partnerId={partnerId}
							name={stringFromModelValue(baseFormProps.model.display_name) ?? ''}
						/>
					) : previewMode === 'referral-overview' ? (
						<ReferralOverviewPreview
							title={stringFromModelValue(selectedReferralFormProps.model.banner_title) ?? ''}
							subtitle={stringFromModelValue(selectedReferralFormProps.model.banner_subtitle) ?? ''}
							logoSrc={selectedReferralLogoUrl}
							referralDefinition={selectedReferralDefinition}
						/>
					) : previewMode === 'referral-linkout' ? (
						<ReferralLinkoutPreview
							title={stringFromModelValue(selectedReferralFormProps.model.banner_title) ?? ''}
							subtitle={stringFromModelValue(selectedReferralFormProps.model.banner_subtitle) ?? ''}
							logoSrc={selectedReferralLogoUrl}
							referralDefinition={selectedReferralDefinition}
							description={stringFromModelValue(selectedReferralFormProps.model.description) ?? ''}
							link_out_1_text={stringFromModelValue(selectedReferralFormProps.model.link_out_1_text) ?? ''}
							link_out_1_type={stringFromModelValue(selectedReferralFormProps.model.link_out_1_type) ?? ''}
							show_link_out_2={selectedReferralFormProps.model.show_link_out_2 === 'true'}
							link_out_2_text={stringFromModelValue(selectedReferralFormProps.model.link_out_2_text) ?? ''}
							link_out_2_type={stringFromModelValue(selectedReferralFormProps.model.link_out_2_type) ?? null}
							show_link_out_3={selectedReferralFormProps.model.show_link_out_3 === 'true'}
							link_out_3_text={stringFromModelValue(selectedReferralFormProps.model.link_out_3_text) ?? ''}
							link_out_3_type={stringFromModelValue(selectedReferralFormProps.model.link_out_3_type) ?? null}
						/>
					) : null}
				</PreviewWrapper>
			</Wrapper>
			<StickySaveButton
				isFormDirty={hasChangedAny || referralHasChangedAny}
				hasTouchedForm={hasTouchedAny || referralHasTouchedAny}
				isLoading={submitLoading}
				dataTestId="create-edit-health-sponsorship-save-btn"
			>
				Save Configuration
			</StickySaveButton>
			{modalIsOpen && (
				<DeleteHealthSponsorshipModal modalIsOpen={modalIsOpen} openModal={openModal} onDelete={onDelete} />
			)}
		</form>
	);
}

function EditHealthSponsorship({
	partnerId,
	sponsorshipId,
}: {
	partnerId: string;
	sponsorshipId: string;
}): ReactElement {
	const { data: sponsorship } = useHealthSponsorship(partnerId, sponsorshipId);
	const { data: referrals } = useHealthReferrals(partnerId, sponsorshipId);
	if (!sponsorship || !referrals) {
		return <Loader />;
	}
	return <HealthSponsorshipForm partnerId={partnerId} sponsorship={sponsorship} referrals={referrals} />;
}

function HealthSponsorshipDetailPage(): ReactElement {
	const partner = useDefinedPartner();
	const { sponsorshipId } = useParams<{ sponsorshipId: string }>();
	return sponsorshipId ? (
		<EditHealthSponsorship partnerId={partner.id} sponsorshipId={sponsorshipId} />
	) : (
		<HealthSponsorshipForm partnerId={partner.id} />
	);
}

export default HealthSponsorshipDetailPage;
