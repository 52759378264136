import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Card } from '@calm-web/design-system';

import { EditHealthReferralFormProps } from '@/hooks/forms/useHealthReferralForm';
import { HealthReferralDefinitions } from '@/types/health';

import ReferralCardInner from './ReferralCardInner';
import ReferralTabs from './ReferralTabs';

function ReferralCard({
	allHealthReferralFormProps,
	selectedReferralFormProps,
	selectedReferralDefinition,
	setSelectedReferralDefinition,
	onFocusOverview,
	onFocusLinkout,
	isDependentGroup,
}: {
	allHealthReferralFormProps: EditHealthReferralFormProps[];
	selectedReferralFormProps: EditHealthReferralFormProps;
	selectedReferralDefinition: HealthReferralDefinitions;
	setSelectedReferralDefinition: Dispatch<SetStateAction<HealthReferralDefinitions>>;
	onFocusOverview?: () => void;
	onFocusLinkout?: () => void;
	isDependentGroup?: boolean;
}): ReactElement {
	return (
		<Card>
			<ReferralTabs
				selectedTab={selectedReferralDefinition}
				setSelectedTab={setSelectedReferralDefinition}
				allHealthReferralFormProps={allHealthReferralFormProps}
				isDependentGroup={isDependentGroup}
			/>
			<ReferralCardInner
				formProps={selectedReferralFormProps}
				selectedTab={selectedReferralDefinition}
				onFocusOverview={onFocusOverview}
				onFocusLinkout={onFocusLinkout}
			/>
		</Card>
	);
}

export default ReferralCard;
